<template>
    <el-container class="page-container">
<!--        打印机管理-->
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->
          <!---->
        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form
                    label-position="left"
                    label-width="80px"
                    :model="query"
                    ref="searchForm"
            >
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input
                                placeholder="请输入内容"
                                v-model="query.searchKeyword"
                                @keyup.enter.native="doSearch"
                        >
                            <el-select
                                    v-model="query.searchName"
                                    slot="prepend"
                                    placeholder="查询类型"
                                    style="width: 120px;"
                            >
                                <el-option label="打印机名" value="name"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="name" label="打印机名" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="host" label="打印机地址" :show-overflow-tooltip="true">
                </el-table-column>

                <el-table-column
                        prop="port"
                        label="打印机端口"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="wanType"
                        label="网口类型"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.wanType==0">网口</span>
                        <span v-if="scope.row.wanType==1">USB</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="locate"
                        label="位置"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.locate==0">后厨</span>
                        <span v-if="scope.row.locate==1">收银</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.state==0">在线</span>
                        <span v-if="scope.row.state==1">离线</span>
                        <span v-if="scope.row.state==-1">停用</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="note"
                        label="备注"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column fixed="right" width="90" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                type="success"
                                @click="handleEdit(scope.row)"
                                plain
                        >修改</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <br />
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount"
            >
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog
                @close="handleClose('editForm')"
                title="修改"
                :dialogVisible="visibleFormDialog"
        >
            <template slot="content">
                <el-form
                        :model="formData"
                        ref="editForm"
                        :rules="formRules"
                        label-position="right"
                        label-width="80px"
                >
                    <el-form-item prop="shopId" label="餐点名称">
                        <el-select v-model="formData.siteId" placeholder="餐点名称" style="width: 100%;">
                            <el-option v-for="(item,index) in siteDataArr" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="name" label="打印机名">
                        <el-input maxlength="100" placeholder="打印机名"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="host" label="地址">
                        <el-input maxlength="100" placeholder="地址"
                                  v-model="formData.host"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="port" label="端口">
                        <el-input maxlength="100" placeholder="端口"
                                  v-model="formData.port"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="wanType" label="网口类型">
                        <el-input maxlength="100" placeholder="网口类型"
                                  v-model="formData.wanType"
                                  auto-complete="off" tabindex="5" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="locate" label="位置">
                        <el-input maxlength="100" placeholder="位置"
                                  v-model="formData.locate"
                                  auto-complete="off" tabindex="6" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="state" label="状态">
                        <el-radio-group v-model="formData.state">
                            <el-radio
                                    :label="0"
                                    size="mini"
                                    border
                                    style="margin-right: 0"
                            >在线</el-radio
                            >
                            <el-radio
                                    :label="1"
                                    size="mini"
                                    border
                                    style="margin-right: 0"
                            >离线</el-radio
                            >
                            <el-radio
                                    :label="-1"
                                    size="mini"
                                    border
                                    style="margin-right: 0"
                            >停用</el-radio
                            >
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="note" label="备注">
                        <el-input maxlength="100" placeholder="备注"
                                  v-model="formData.note"
                                  auto-complete="off" tabindex="8" type="text"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                        <el-button @click="closeModul">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>
</template>

<script>
  import { mapGetters } from "vuex";
  import formDialog from "../../components/formDialog";
  import { printsData, foodSiteAdd ,foodSiteEdit,printsEdit} from "../../api/storeArea";
  import {siteData} from '../../api/ads'
  export default {
    name: "companyManagement",
    components: { formDialog },
    data() {
      let validateBusinessEnd = (rule, value, callback) => {
        if (!this.formData.businessEnd||!value) {
          callback(new Error('营业时间'));
        } else {
          callback();
        }
      };
      let validatemobile = (rule, value, callback) => {
        if (!value) {
          callback(new Error('联系电话'));
        }else if((!(/^1[3456789]\d{9}$/.test(value)))){
          callback(new Error('请输入正确的联系电话'));
        } else {
          callback();
        }
      };
      return {
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName: "opAt",
          pageOrderBy: "descending"
        },
        query: {
          searchName: "",
          searchKeyword: ""
        },
        visibleFormDialog: false,
        visibleAddDialog: false,
        formData: {
        },
        formRules: {
          siteId: [
            {required: true, message: '餐点ID', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '打印机名称', trigger: 'blur'}
          ],
          host: [
            {required: true, message: '打印机地址', trigger: 'blur'}
          ],
          port: [
            {required: true, message: '打印机端口', trigger: 'blur'}
          ],
          wanType: [
            {required: true, message: '网口类型', trigger: 'blur'}
          ],
          locate: [
            {required: true, message: '打印机位置', trigger: 'blur'}
          ],
          state: [
            {required: true, message: '打印机状态', trigger: 'blur'}
          ],
          note: [
            { message: '备注', trigger: 'blur'}
          ],
        },
        siteDataArr:[]
      };
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    methods: {
      handleShowPassword() {
        this.inputType = this.inputType === "password" ? "text" : "password";
      },
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.printsData();
      },
      async printsData() {
        try {
          let query = Object.assign({}, this.pageInfo, this.query);
          let formData = {};
          for (let i in query) {
            if (query[i] !== "" && i !== "total" && query[i] !== -1) {
              formData[i] = query[i];
            }
          }
          let resonse = await printsData(formData);
          let { data } = resonse;
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e);
        }
      },
      clearSearch() {
        (this.query = {
          searchName: "",
          searchKeyword: ""
        }),
          this.handleSearch();
      },
      closeModul() {
        this.visibleFormDialog = false;
        this.printsData();
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.pageInfo.page = 1;
        this.printsData();
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val;
        this.printsData();
      },
      handleClose(form) {
        this.$refs[form].resetFields();
        this.visibleFormDialog = false;
        this.visibleAddDialog = false;
      },
      handleEdit(row) {
        console.log(428, row);
        this.formData = row;
        this.visibleFormDialog = true;
      },
      async doEdit() {
        this.$refs["editForm"].validate(async valid => {
          if (valid) {
            try {
              await printsEdit(this.formData);
              this.$message({
                type: "success",
                message: "更新成功!"
              });
              this.visibleFormDialog = false;
              this.printsData();
            } catch (e) {}
          }
        });
      },
      async siteData(){
        let {data}=await siteData();
        this.siteDataArr=data.list;
        console.log(482,data.list)
      },
    },
    mounted() {
      this.printsData();
      this.siteData();
    }
  };
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }
    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348eed;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #dcdfe6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409eff;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }
</style>
